import React, {useEffect} from 'react';

type PaginatedDataOptions = {
	perPage: number;
	initialPage?: number;
};

const usePaginatedData = (records: any[], {perPage, initialPage = 1}: PaginatedDataOptions) => {
	const [currentPage, setCurrentPage] = React.useState(initialPage);

	const totalPages = Math.ceil(records.length / perPage);

	const paginatedData = React.useMemo(() => {
		const start = (currentPage - 1) * perPage;
		const end = start + perPage;
		return records.slice(start, end);
	}, [currentPage, perPage, records]);

	useEffect(() => {
		setCurrentPage(initialPage);
	}, [initialPage, records]);

	return {
		currentPage,
		totalPages,
		setCurrentPage,
		paginatedData,
	};
};

export default usePaginatedData;
